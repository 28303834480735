import "./main.css";
import { jsxs as b, Fragment as w, jsx as r } from "react/jsx-runtime";
import { useState as O, createContext as z, useContext as Y, useCallback as Q, useEffect as X, useMemo as k, forwardRef as Z, Fragment as ee, useId as he } from "react";
import { Modal as pe, Button as x, Popover as ge, FormGroup as te, ValidatedOptions as I, Select as ne, SelectVariant as be, SelectOption as re, Switch as ye, TextInput as le, TextArea as Ce, NumberInput as Ie, AlertGroup as ve, Alert as Te, AlertVariant as _, AlertActionCloseButton as Se, InputGroup as ae, ButtonVariant as $, Checkbox as ke, Radio as xe, Text as Oe, Title as Ne, Card as Ve, CardHeader as Ae, CardTitle as qe, CardBody as we, Grid as Fe, GridItem as H, PageSection as _e, JumpLinks as Le, JumpLinksItem as Re } from "@patternfly/react-core";
import { useFormContext as D, Controller as N, useController as se, FormProvider as De, useWatch as Pe } from "react-hook-form";
import { HelpIcon as G, CubeIcon as Ee, PaypalIcon as Me, InstagramIcon as $e, BitbucketIcon as He, MicrosoftIcon as Ge, TwitterIcon as Be, StackOverflowIcon as je, OpenshiftIcon as Ue, LinkedinIcon as Je, GoogleIcon as We, GitlabIcon as Ke, FacebookSquareIcon as ze, GithubIcon as Ye, MinusCircleIcon as Qe, PlusCircleIcon as Xe } from "@patternfly/react-icons";
import { get as B } from "lodash-es";
const Lt = ({
  modalTitle: e,
  continueLabel: n,
  cancelLabel: t,
  buttonTitle: a,
  isDisabled: l,
  buttonVariant: s,
  onContinue: o,
  component: i = x,
  children: u,
  ...d
}) => {
  const [p, h] = O(!1);
  return /* @__PURE__ */ b(w, { children: [
    /* @__PURE__ */ r(
      i,
      {
        variant: s,
        onClick: () => h(!0),
        isDisabled: l,
        children: a
      }
    ),
    /* @__PURE__ */ r(
      pe,
      {
        variant: "small",
        ...d,
        title: e,
        isOpen: p,
        onClose: () => h(!1),
        actions: [
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-confirm",
              variant: "primary",
              onClick: () => {
                h(!1), o();
              },
              children: n
            },
            "confirm"
          ),
          /* @__PURE__ */ r(
            x,
            {
              id: "modal-cancel",
              variant: "secondary",
              onClick: () => h(!1),
              children: t
            },
            "cancel"
          )
        ],
        children: u
      }
    )
  ] });
};
function Ze(e, n) {
  const t = z(n);
  return t.displayName = e, t;
}
function et(e) {
  return e != null;
}
function tt(e) {
  const n = Y(e);
  if (et(n))
    return n;
  throw new Error(
    `No provider found for ${e.displayName ? `the '${e.displayName}'` : "an unknown"} context, make sure it is included in your component hierarchy.`
  );
}
function nt(e, n, t) {
  const [a, l] = O(
    () => e.getItem(n) ?? t
  ), s = Q((o) => {
    l(o), e.setItem(n, o);
  }, []);
  return X(() => {
    l(e.getItem(n) ?? t), window.addEventListener("storage", o);
    function o(i) {
      i.storageArea === e && (i.key === null || i.key === n) && l(i.newValue ?? t);
    }
    return () => window.removeEventListener("storage", o);
  }, [e, n]), [a, s];
}
function rt(e, n, t) {
  const a = k(
    () => JSON.stringify(t),
    [t]
  ), [l, s] = nt(
    e,
    n,
    a
  ), o = k(() => JSON.parse(l), [l]), i = Q(
    (u) => s(JSON.stringify(u)),
    []
  );
  return [o, i];
}
const oe = Ze(
  "HelpContext",
  void 0
), lt = () => tt(oe), Rt = ({ children: e }) => {
  const [n, t] = rt(localStorage, "helpEnabled", !0);
  function a() {
    t(!n);
  }
  return /* @__PURE__ */ r(oe.Provider, { value: { enabled: n, toggleHelp: a }, children: e });
}, ie = ({
  helpText: e,
  fieldLabelId: n,
  noVerticalAlign: t = !0,
  unWrap: a = !1
}) => {
  const { enabled: l } = lt();
  return l ? /* @__PURE__ */ r(ge, { bodyContent: e, children: /* @__PURE__ */ b(w, { children: [
    !a && /* @__PURE__ */ r(
      "button",
      {
        "data-testid": `help-label-${n}`,
        "aria-label": n,
        onClick: (s) => s.preventDefault(),
        className: "pf-c-form__group-label-help",
        children: /* @__PURE__ */ r(G, { noVerticalAlign: t })
      }
    ),
    a && /* @__PURE__ */ r(G, { noVerticalAlign: t })
  ] }) }) : null;
}, V = ({
  name: e,
  label: n,
  labelIcon: t,
  error: a,
  children: l,
  ...s
}) => /* @__PURE__ */ r(
  te,
  {
    label: n || e,
    fieldId: e,
    labelIcon: t ? /* @__PURE__ */ r(ie, { helpText: t, fieldLabelId: e }) : void 0,
    helperTextInvalid: a?.message,
    validated: a ? I.error : I.default,
    ...s,
    children: l
  }
), at = ({
  name: e,
  label: n,
  options: t,
  controller: a,
  variant: l,
  labelIcon: s,
  ...o
}) => {
  const {
    control: i,
    formState: { errors: u }
  } = D(), [d, p] = O(!1);
  return /* @__PURE__ */ r(
    V,
    {
      name: e,
      label: n,
      isRequired: a.rules?.required === !0,
      error: u[e],
      labelIcon: s,
      children: /* @__PURE__ */ r(
        N,
        {
          ...a,
          name: e,
          control: i,
          render: ({ field: { onChange: h, value: m } }) => /* @__PURE__ */ r(
            ne,
            {
              ...o,
              toggleId: e.slice(e.lastIndexOf(".") + 1),
              onToggle: (c) => p(c),
              selections: typeof t[0] != "string" ? t.filter((c) => m.includes(c.key)).map((c) => c.value) : m,
              onSelect: (c, f) => {
                if (l === "typeaheadmulti") {
                  const v = f.toString();
                  m.includes(v) ? h(m.filter((S) => S !== v)) : h([...m, v]);
                } else
                  h(f), p(!1);
              },
              onClear: l !== be.single ? (c) => {
                c.stopPropagation(), h([]);
              } : void 0,
              isOpen: d,
              variant: l,
              validated: u[e] ? I.error : I.default,
              children: t.map((c) => /* @__PURE__ */ r(
                re,
                {
                  value: typeof c == "string" ? c : c.key,
                  children: typeof c == "string" ? c : c.value
                },
                typeof c == "string" ? c : c.key
              ))
            }
          )
        }
      )
    }
  );
}, Dt = (e) => {
  const n = e.defaultValue ?? !1, { control: t } = D();
  return /* @__PURE__ */ r(
    V,
    {
      name: e.name,
      isRequired: e.rules?.required === !0,
      label: e.label,
      labelIcon: e.labelIcon,
      children: /* @__PURE__ */ r(
        N,
        {
          control: t,
          name: e.name,
          defaultValue: n,
          render: ({ field: { onChange: a, value: l } }) => /* @__PURE__ */ r(
            ye,
            {
              id: e.name,
              "data-testid": e.name,
              label: e.labelOn,
              labelOff: e.labelOff,
              isChecked: e.stringify ? l === "true" : l,
              onChange: (s, o) => {
                const i = e.stringify ? s.toString() : s;
                e.onChange?.(s, o), a(i);
              }
            }
          )
        }
      )
    }
  );
}, P = Z(({ onChange: e, ...n }, t) => /* @__PURE__ */ r(le, { ...n, ref: t, onChange: (l, s) => e?.(s) }));
P.displayName = "TextInput";
const Pt = (e) => {
  const { labelIcon: n, ...t } = e, a = !!e.rules?.required, l = e.defaultValue ?? "", { field: s, fieldState: o } = se({
    ...e,
    defaultValue: l
  });
  return /* @__PURE__ */ r(
    V,
    {
      name: e.name,
      label: e.label,
      labelIcon: n,
      isRequired: a,
      error: o.error,
      children: /* @__PURE__ */ r(
        P,
        {
          isRequired: a,
          id: e.name,
          "data-testid": e.name,
          validated: o.error ? I.error : I.default,
          isDisabled: e.isDisabled,
          ...t,
          ...s
        }
      )
    }
  );
}, E = Z(({ onChange: e, ...n }, t) => /* @__PURE__ */ r(Ce, { ...n, ref: t, onChange: (l, s) => e?.(s) }));
E.displayName = "TextArea";
const Et = (e) => {
  const n = !!e.rules?.required, t = e.defaultValue ?? "", { field: a, fieldState: l } = se({
    ...e,
    defaultValue: t
  });
  return /* @__PURE__ */ r(
    V,
    {
      isRequired: n,
      label: e.label,
      labelIcon: e.labelIcon,
      name: e.name,
      error: l.error,
      children: /* @__PURE__ */ r(
        E,
        {
          isRequired: n,
          id: e.name,
          "data-testid": e.name,
          validated: l.error ? I.error : I.default,
          isDisabled: e.isDisabled,
          ...a
        }
      )
    }
  );
}, Mt = ({
  name: e,
  label: n,
  controller: t,
  labelIcon: a,
  ...l
}) => {
  const {
    control: s,
    formState: { errors: o }
  } = D();
  return /* @__PURE__ */ r(
    V,
    {
      name: e,
      label: n,
      isRequired: t.rules?.required === !0,
      error: o[e],
      labelIcon: a,
      children: /* @__PURE__ */ r(
        N,
        {
          ...t,
          name: e,
          control: s,
          render: ({ field: i }) => {
            const u = !!t.rules?.required, d = t.rules?.min, p = i.value === 0 ? t.defaultValue : i.value, h = (m) => i.onChange(d ? Math.max(m, Number(d)) : m);
            return /* @__PURE__ */ r(
              Ie,
              {
                ...l,
                id: e,
                value: p,
                validated: o[e] ? I.error : I.default,
                required: u,
                min: Number(d),
                max: Number(t.rules?.max),
                onPlus: () => h(p + 1),
                onMinus: () => h(p - 1),
                onChange: (m) => {
                  const c = Number(m.currentTarget.value);
                  h(isNaN(c) ? t.defaultValue : c);
                }
              }
            );
          }
        }
      )
    }
  );
}, ce = z(void 0), $t = () => Y(ce), Ht = ({ children: e }) => {
  const [n, t] = O([]), a = (o) => {
    t((i) => i.filter((u) => u.id !== o));
  }, l = (o, i = _.success, u) => {
    t([
      {
        id: Math.random() * 100,
        message: o,
        variant: i,
        description: u
      },
      ...n
    ]);
  }, s = (o) => {
    l(o, _.danger);
  };
  return /* @__PURE__ */ b(ce.Provider, { value: { addAlert: l, addError: s }, children: [
    /* @__PURE__ */ r(ve, { isToast: !0, "data-testid": "alerts", children: n.map(({ id: o, variant: i, message: u, description: d }) => /* @__PURE__ */ r(
      Te,
      {
        isLiveRegion: !0,
        variant: _[i],
        variantLabel: "",
        title: u,
        actionClose: /* @__PURE__ */ r(
          Se,
          {
            title: u,
            onClose: () => a(o)
          }
        ),
        timeout: !0,
        onTimeout: () => a(o),
        children: d && /* @__PURE__ */ r("p", { children: d })
      },
      o
    )) }),
    e
  ] });
}, Gt = ({ icon: e }) => {
  const n = st(e);
  return /* @__PURE__ */ r(n, { size: "lg", alt: e });
};
function st(e) {
  switch (e) {
    case "github":
      return Ye;
    case "facebook":
      return ze;
    case "gitlab":
      return Ke;
    case "google":
      return We;
    case "linkedin":
    case "linkedin-openid-connect":
      return Je;
    case "openshift-v3":
    case "openshift-v4":
      return Ue;
    case "stackoverflow":
      return je;
    case "twitter":
      return Be;
    case "microsoft":
      return Ge;
    case "bitbucket":
      return He;
    case "instagram":
      return $e;
    case "paypal":
      return Me;
    default:
      return Ee;
  }
}
const ot = (e) => {
  try {
    return new Intl.DisplayNames([e], { type: "language" }).of(e);
  } catch {
    return e;
  }
}, it = ({
  t: e,
  form: n,
  supportedLocales: t
}) => {
  const a = t.map((l) => ({
    key: l,
    value: ot(l) || ""
  }));
  return /* @__PURE__ */ r(De, { ...n, children: /* @__PURE__ */ r(
    at,
    {
      "data-testid": "locale-select",
      name: "attributes.locale",
      label: e("selectALocale"),
      controller: { defaultValue: "" },
      options: a
    }
  ) });
}, ue = (e) => e?.includes("${"), M = (e) => e.substring(2, e.length - 1), L = (e, n, t) => (ue(n) ? e(M(n)) : n) || t, R = (e, n) => L(e, n.displayName, n.name), ct = ["username", "firstName", "lastName", "email"], de = (e) => e && ct.includes(e), T = (e) => `${de(e) ? "" : "attributes."}${e?.replaceAll(
  ".",
  "🍺"
)}`;
function Bt(e, n, t) {
  (e.responseData.errors !== void 0 ? e.responseData.errors : [e.responseData]).forEach((a) => {
    const l = Object.assign(
      {},
      a.params?.map((s) => t(ue(s.toString()) ? M(s) : s))
    );
    n(T(a.field), {
      message: t(a.errorMessage, {
        ...l,
        defaultValue: a.field
      }),
      type: "server"
    });
  });
}
function A({
  required: e,
  validators: n
}) {
  return e || ut(n);
}
function ut(e) {
  return e && "length" in e && "min" in e.length && typeof e.length.min == "number" ? e.length.min > 0 : !1;
}
function jt(e) {
  if (typeof e != "object" || e === null || !("responseData" in e))
    return !1;
  const { responseData: n } = e;
  return j(n) ? !0 : typeof n != "object" || n === null || !("errors" in n) || !Array.isArray(n.errors) ? !1 : n.errors.every(j);
}
function j(e) {
  return !(typeof e != "object" || e === null || !("field" in e) || typeof e.field != "string" || !("errorMessage" in e) || typeof e.errorMessage != "string");
}
const q = ({
  t: e,
  form: n,
  attribute: t,
  renderer: a,
  children: l
}) => {
  const s = t.annotations?.inputHelperTextBefore, {
    formState: { errors: o }
  } = n, i = a?.(t);
  return /* @__PURE__ */ r(
    te,
    {
      label: R(e, t) || "",
      fieldId: t.name,
      isRequired: A(t),
      validated: B(o, T(t.name)) ? "error" : "default",
      helperTextInvalid: e(
        B(o, T(t.name))?.message
      ),
      labelIcon: s ? /* @__PURE__ */ r(ie, { helpText: s, fieldLabelId: t.name }) : void 0,
      children: i ? /* @__PURE__ */ b(ae, { children: [
        l,
        i
      ] }) : l
    },
    t.name
  );
}, dt = ({
  t: e,
  form: n,
  attribute: t,
  renderer: a
}) => /* @__PURE__ */ r(q, { t: e, form: n, attribute: t, renderer: a, children: /* @__PURE__ */ r(
  mt,
  {
    t: e,
    form: n,
    "aria-label": R(e, t),
    name: T(t.name),
    addButtonLabel: e("addMultivaluedLabel", {
      fieldLabel: R(e, t)
    })
  }
) }), mt = ({
  t: e,
  name: n,
  form: t,
  addButtonLabel: a,
  isDisabled: l = !1,
  defaultValue: s,
  id: o,
  ...i
}) => {
  const { register: u, setValue: d, control: p } = t, h = Pe({
    name: n,
    control: p,
    defaultValue: s || ""
  }), m = k(() => Array.isArray(h) && h.length !== 0 ? h : s || [""], [h]), c = (y) => {
    S([...m.slice(0, y), ...m.slice(y + 1)]);
  }, f = () => {
    S([...m, ""]);
  }, v = (y, g) => {
    S([...m.slice(0, y), g, ...m.slice(y + 1)]);
  }, S = (y) => {
    const g = y.flatMap((F) => F);
    d(n, g, {
      shouldDirty: !0
    });
  };
  return X(() => {
    u(n);
  }, [u]), /* @__PURE__ */ r("div", { id: o, children: m.map((y, g) => /* @__PURE__ */ b(ee, { children: [
    /* @__PURE__ */ b(ae, { children: [
      /* @__PURE__ */ r(
        le,
        {
          "data-testid": n + g,
          onChange: (F) => v(g, F),
          name: `${n}.${g}.value`,
          value: y,
          isDisabled: l,
          ...i
        }
      ),
      /* @__PURE__ */ r(
        x,
        {
          "data-testid": "remove" + g,
          variant: $.link,
          onClick: () => c(g),
          tabIndex: -1,
          "aria-label": e("remove"),
          isDisabled: m.length === 1 || l,
          children: /* @__PURE__ */ r(Qe, {})
        }
      )
    ] }),
    g === m.length - 1 && /* @__PURE__ */ b(
      x,
      {
        variant: $.link,
        onClick: f,
        tabIndex: -1,
        "aria-label": e("add"),
        "data-testid": "addValue",
        isDisabled: !y || l,
        children: [
          /* @__PURE__ */ r(Xe, {}),
          " ",
          e(a || "add")
        ]
      }
    )
  ] }, g)) });
}, U = (e) => {
  const { form: n, inputType: t, attribute: a } = e, l = A(a), s = t.startsWith("multiselect"), o = s ? ke : xe, i = a.validators?.options?.options || [];
  return /* @__PURE__ */ r(q, { ...e, children: /* @__PURE__ */ r(
    N,
    {
      name: T(a.name),
      control: n.control,
      defaultValue: "",
      render: ({ field: u }) => /* @__PURE__ */ r(w, { children: i.map((d) => /* @__PURE__ */ r(
        o,
        {
          id: d,
          "data-testid": d,
          label: d,
          value: d,
          isChecked: u.value.includes(d),
          onChange: () => {
            s ? u.value.includes(d) ? u.onChange(
              u.value.filter((p) => p !== d)
            ) : u.onChange([...u.value, d]) : u.onChange([d]);
          },
          readOnly: a.readOnly,
          isRequired: l
        },
        d
      )) })
    }
  ) });
}, J = (e) => {
  const { t: n, form: t, inputType: a, attribute: l } = e, [s, o] = O(!1), i = A(l), u = a === "multiselect", d = (c, f) => {
    u ? f.value.includes(c) ? f.onChange(f.value.filter((v) => v !== c)) : f.onChange([...f.value, c]) : f.onChange(c);
  }, p = l.validators?.options?.options || [], h = l.annotations?.inputOptionLabels, m = (c) => h ? n(M(h[c])) : c;
  return /* @__PURE__ */ r(q, { ...e, children: /* @__PURE__ */ r(
    N,
    {
      name: T(l.name),
      defaultValue: "",
      control: t.control,
      render: ({ field: c }) => /* @__PURE__ */ r(
        ne,
        {
          toggleId: l.name,
          onToggle: (f) => o(f),
          isCreatable: !0,
          onCreateOption: (f) => d(f, c),
          onSelect: (f, v) => {
            const S = v.toString();
            d(S, c), Array.isArray(c.value) || o(!1);
          },
          selections: c.value ? c.value : u ? [] : n("choose"),
          variant: u ? "typeaheadmulti" : "single",
          "aria-label": n("selectOne"),
          isOpen: s,
          isDisabled: l.readOnly,
          required: i,
          children: ["", ...p].map((f) => /* @__PURE__ */ r(
            re,
            {
              selected: c.value === f,
              value: f,
              children: f ? m(f) : n("choose")
            },
            f
          ))
        }
      )
    }
  ) });
}, ft = (e) => {
  const { form: n, attribute: t } = e, a = A(t);
  return /* @__PURE__ */ r(q, { ...e, children: /* @__PURE__ */ r(
    E,
    {
      id: t.name,
      "data-testid": t.name,
      ...n.register(T(t.name)),
      cols: t.annotations?.inputTypeCols,
      rows: t.annotations?.inputTypeRows,
      readOnly: t.readOnly,
      isRequired: a
    }
  ) });
}, C = (e) => {
  const { form: n, inputType: t, attribute: a } = e, l = A(a), s = t.startsWith("html") ? t.substring(4 + 2) : "text";
  return /* @__PURE__ */ r(q, { ...e, children: /* @__PURE__ */ r(
    P,
    {
      id: a.name,
      "data-testid": a.name,
      type: s,
      placeholder: a.annotations?.inputTypePlaceholder,
      readOnly: a.readOnly,
      isRequired: l,
      ...n.register(T(a.name))
    }
  ) });
}, me = {
  text: C,
  textarea: ft,
  select: J,
  "select-radiobuttons": U,
  multiselect: J,
  "multiselect-checkboxes": U,
  "html5-email": C,
  "html5-tel": C,
  "html5-url": C,
  "html5-number": C,
  "html5-range": C,
  "html5-datetime-local": C,
  "html5-date": C,
  "html5-month": C,
  "html5-time": C,
  "multi-input": dt
}, Ut = ({
  t: e,
  form: n,
  userProfileMetadata: t,
  supportedLocales: a,
  hideReadOnly: l = !1,
  renderer: s
}) => {
  const o = k(() => {
    if (!t.attributes)
      return [];
    const i = l ? t.attributes.filter(({ readOnly: u }) => !u) : t.attributes;
    return [
      // Insert an empty group for attributes without a group.
      { name: void 0 },
      ...t.groups ?? []
    ].map((u) => ({
      group: u,
      attributes: i.filter(
        (d) => d.group === u.name
      )
    }));
  }, [
    l,
    t.groups,
    t.attributes
  ]);
  return o.length === 0 ? null : /* @__PURE__ */ r(
    Ot,
    {
      label: e("jumpToSection"),
      sections: o.filter((i) => i.attributes.length > 0).map(({ group: i, attributes: u }) => ({
        title: L(e, i.displayHeader, i.name) || e("general"),
        panel: /* @__PURE__ */ b("div", { className: "pf-c-form", children: [
          i.displayDescription && /* @__PURE__ */ r(Oe, { className: "pf-u-pb-lg", children: L(e, i.displayDescription, "") }),
          u.map((d) => /* @__PURE__ */ r(
            ht,
            {
              t: e,
              form: n,
              supportedLocales: a,
              renderer: s,
              attribute: d
            },
            d.name
          ))
        ] })
      }))
    }
  );
}, ht = ({
  t: e,
  form: n,
  renderer: t,
  supportedLocales: a,
  attribute: l
}) => {
  const s = n.watch(
    T(l.name)
  ), o = k(
    () => gt(l, s),
    [l]
  ), i = me[o];
  return l.name === "locale" ? /* @__PURE__ */ r(
    it,
    {
      form: n,
      supportedLocales: a,
      t: e,
      attribute: l
    }
  ) : /* @__PURE__ */ r(
    i,
    {
      t: e,
      form: n,
      inputType: o,
      attribute: l,
      renderer: t
    }
  );
}, pt = "text";
function gt(e, n) {
  if (de(e.name))
    return "text";
  const t = e.annotations?.inputType;
  return bt(t) ? t : yt(n) ? "multi-input" : pt;
}
const bt = (e) => typeof e == "string" && e in me, yt = (e) => Array.isArray(e) && e.length > 1, Ct = "_title_1nyfo_2", It = {
  title: Ct
}, fe = ({
  id: e,
  title: n,
  headingLevel: t = "h1",
  size: a = "xl",
  ...l
}) => /* @__PURE__ */ r(
  Ne,
  {
    headingLevel: t,
    size: a,
    className: It.title,
    id: e,
    tabIndex: 0,
    ...l,
    children: n
  }
), vt = ({
  title: e,
  children: n,
  scrollId: t,
  className: a
}) => {
  const l = he();
  return /* @__PURE__ */ b(Ve, { id: l, className: a, isFlat: !0, children: [
    /* @__PURE__ */ r(Ae, { className: "kc-form-panel__header", children: /* @__PURE__ */ r(qe, { tabIndex: 0, children: /* @__PURE__ */ r(fe, { id: t, title: e }) }) }),
    /* @__PURE__ */ r(we, { className: "kc-form-panel__body", children: n })
  ] });
}, Tt = (e) => {
  const { title: n, children: t, scrollId: a, ...l } = e;
  return /* @__PURE__ */ r("section", { ...l, style: { marginTop: "var(--pf-global--spacer--lg)" }, children: /* @__PURE__ */ b(w, { children: [
    /* @__PURE__ */ r(fe, { id: a, title: n }),
    t
  ] }) });
}, St = "_panel_cd9gh_1", kt = "_sticky_cd9gh_5", W = {
  panel: St,
  sticky: kt
}, xt = "kc-main-content-page-container", K = (e) => e.replace(/\s+/g, "-"), Ot = ({
  label: e,
  sections: n,
  borders: t = !1,
  ...a
}) => {
  const l = k(
    () => n.filter(({ isHidden: s }) => !s),
    [n]
  );
  return /* @__PURE__ */ b(Fe, { hasGutter: !0, ...a, children: [
    /* @__PURE__ */ r(H, { md: 8, sm: 12, children: l.map(({ title: s, panel: o }) => {
      const i = K(s.toLowerCase());
      return /* @__PURE__ */ r(ee, { children: t ? /* @__PURE__ */ r(
        vt,
        {
          scrollId: i,
          title: s,
          className: W.panel,
          children: o
        }
      ) : /* @__PURE__ */ r(Tt, { scrollId: i, title: s, children: o }) }, s);
    }) }),
    /* @__PURE__ */ r(H, { md: 4, sm: 12, order: { default: "-1", md: "1" }, children: /* @__PURE__ */ r(_e, { className: W.sticky, children: /* @__PURE__ */ r(
      Le,
      {
        isVertical: !0,
        scrollableSelector: `#${xt}`,
        label: e,
        offset: 100,
        children: l.map(({ title: s }) => {
          const o = K(s.toLowerCase());
          return (
            // note that JumpLinks currently does not work with spaces in the href
            /* @__PURE__ */ r(
              Re,
              {
                href: `#${o}`,
                "data-testid": `jump-link-${o}`,
                children: s
              },
              s
            )
          );
        })
      }
    ) }) })
  ] });
};
export {
  Ht as AlertProvider,
  Lt as ContinueCancelModal,
  vt as FormPanel,
  Rt as Help,
  ie as HelpItem,
  Gt as IconMapper,
  E as KeycloakTextArea,
  P as KeycloakTextInput,
  Mt as NumberControl,
  Ot as ScrollForm,
  at as SelectControl,
  Dt as SwitchControl,
  Et as TextAreaControl,
  Pt as TextControl,
  Ut as UserProfileFields,
  Ze as createNamedContext,
  et as isDefined,
  jt as isUserProfileError,
  L as label,
  xt as mainPageContentId,
  Bt as setUserProfileServerError,
  $t as useAlerts,
  lt as useHelp,
  tt as useRequiredContext,
  rt as useStoredState
};
